import React from "react"
import style from "./form-header.mod.scss"

const FormHeader = ({ heading, text, border = false }) => {
  let classes = [style.header]
  border && classes.push(`${style.header}--border`)

  return (
    <div className={[...classes].join(" ")}>
      <h4>{heading}</h4>
      {text && <p>{text}</p>}
    </div>
  )
}

export default FormHeader
