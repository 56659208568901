import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import SimpleIntro from "../components/global/simple-intro"
import Intro from "../components/collections/intro"
import ThankyouSetPasswordForm from "../components/thankyou/thankyou-set-password-form"
import { AuthContext } from "../context/AuthContext"
import Button from "../components/button/button"
import { getOrders } from "../services/orders"
import { getUser } from "../services/auth"

const ThankYouPage = ({
  component: Component,
  location,
  ...rest
}) => {
  const {
    handleContextGetCartCount,
    contextCartCount,
    contextHandleCanGuestChangePassword,
    contextSetCanGuestChangePassword,
    contextCanGuestChangePassword,
  } = useContext(AuthContext)

  const data = useStaticQuery(graphql`
    {
      datoCmsMiscContent {
        orderRecievedHeading
        orderRecievedDescription
      }
      allPortalWine {
        wineNodes: nodes {
          category
          id
          name
          wine_type
          wine_tags {
            id
            name
          }
          grape {
            name
            id
          }
          winery {
            name
            minimum_wines_per_order
            id
            region {
              name
            }
          }
        }
      }
    }
  `)

  const allWines = data.allPortalWine.wineNodes

  //

  const [isGuest, setIsGuest] = useState(false)
  const [email, setEmail] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [cartCount, setCartCount] = useState(false)
  const [canGuestChangePassword, setCanGuestChangePassword] = useState([])

  const [zapSent, setZapSent] = useState(false)

  useEffect(() => {
    setCartCount(contextCartCount)
  })
  useEffect(() => {
    setCanGuestChangePassword(contextCanGuestChangePassword)
  })

  const sendZap = async () => {
    if (zapSent) {
      return
    }
    let total_orders_number = 0
    let total_purchase_value = 0
    let orders_number = 0
    let orders_total = 0
    let orders_number_cellar = 0
    let orders_total_cellar = 0
    let tags = new Set()
    let vip = false

    tags.add("Contact | Purchaser")
    tags.add("Customer")

    const getOrdersData = {
      user: getUser(),
    }

    const orderRes = await getOrders(getOrdersData).then((res) => {
      if (res.data) {
        total_orders_number = res.data.orders.length
        res.data.orders.map((order) => {
          //loop allWines and find the first wine that matches winery_id
          const wine = allWines.find((wine) => {
            return wine.winery.id === order.winery_id
          })
          if (wine && wine.wine_tags.length) {
            //check if wine_tags array contains cellar
            if (
              wine.wine_tags.find((tag) => {
                return tag.id == "9"
              })
            ) {
              tags.add("Purchase Type | Private Cellar")
              orders_number_cellar++
              orders_total_cellar = orders_total_cellar + order.total_amount
            } else {
              orders_number++
              orders_total = orders_total + order.total_amount
              tags.add("Purchase Type | D2C")
            }
          } else {
            orders_number++
            orders_total = orders_total + order.total_amount
            tags.add("Purchase Type | D2C")
          }
          total_purchase_value = total_purchase_value + order.total_amount
          if (total_purchase_value > 500) {
            tags.add("Contact | Purchaser VIP")
            vip = true
          }
        })
        return res
      }
    })

    if (orderRes) {
      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      //loop location.state.checkoutData.item
      location.state.checkoutData.data.item.map((item) => {
        const wine = allWines.find((wine) => {
          return wine.id == item.wine_id
        })
        tags.add("Wine | " + wine.category + " | " + wine.grape[0].name)
        tags.add("Wine | " + wine.category)
      })

      let MCTags = []
      const tagsArr = Array.from(tags)
      //loop tags array
      tagsArr.map((tag) => {
        MCTags.push({ name: tag, status: "active" })
      })

      // console.log("checkout_data", location.state.checkoutData)
      // console.log("checkout_data", allWines)

      const isSubscribed = location.state.marketingOptin
        ? "subscribed"
        : "unsubscribed"
      //loop location.state.checkoutData.item and find the first wine that matches wine_id

      const data = {
        user_details: location.state.details,
        is_guest: location.state.guest,
        checkout_data: location.state.checkoutData,
        total_orders_number: total_orders_number,
        orders_number: orders_number,
        orders_total: orders_total,
        orders_number_cellar: orders_number_cellar,
        orders_total_cellar: orders_total_cellar,
        total_purchase_value: total_purchase_value,
        tags: MCTags,
        isSubscribed: isSubscribed,
        vip: vip,
      }
      // console.log("SEARCH DATA", data)
      const response = await fetch(process.env.GATSBY_MAKE_ORDER_WEB_HOOK, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
      }).then((res) => {})
      setZapSent(true)
      return response
    }
  }

  useEffect(() => {
    handleContextGetCartCount()
    contextHandleCanGuestChangePassword()
    if (location.state && location.state.guest) {
      setIsGuest(location.state.guest)
      setEmail(location.state.email)
    }
    sendZap()
  }, [])

  useEffect(() => {
    if (isGuest) {
      // console.log("isGuest", isGuest, canGuestChangePassword, email, isGuest)
      if (!canGuestChangePassword) {
        contextSetCanGuestChangePassword(true, true, location.state.email)
      } else {
        setEmail(canGuestChangePassword.email)
        if (canGuestChangePassword.allowed === false) {
          setIsGuest(false)
        }
      }
    }
  })

  const handleThankyouSetPasswordForm = () => {
    setPasswordChanged(true)
    contextSetCanGuestChangePassword(false, false, location.state.guest)
  }

  return (
    <Layout whitePreFooter={true}>
      <Intro
        title={"Thank You!"}
        description={
          "Thank you for your order. We've sent you an email with your order details."
        }
      />
      <Container pb={"1"} gutters width={"xsmall"}>
        <SimpleIntro title={data.datoCmsMiscContent.orderRecievedHeading} />
        <p>{data.datoCmsMiscContent.orderRecievedDescription}</p>
      </Container>

      {cartCount && cartCount > 0 && (
        <Container pb={"2"} gutters width={"xsmall"}>
          <p>{"You still have items in your cart from other wineries."}</p>
          <Container pt={"2"}>
            <Button primary customPath={"/cart"}>
              {"Back to cart"}
            </Button>
          </Container>
        </Container>
      )}

      <Container pb={"2"} gutters width={"xsmall"}>
        {isGuest && !passwordChanged && (
          <ThankyouSetPasswordForm
            email={email}
            handleThankyouSetPasswordForm={handleThankyouSetPasswordForm}
          />
        )}
      </Container>
    </Layout>
  )
}
export default ThankYouPage
