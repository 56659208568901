export const updateProfile = async (data) => {
  //TODO - Check auth first. Can't get checkout if not logged in.

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/update-profile`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    return response
  } catch (error) {
    return error
  }
}

export const changePassword = async (data) => {
  //TODO - Check auth first. Can't get checkout if not logged in.

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/change-password`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    return response
  } catch (error) {
    return error
  }
}
