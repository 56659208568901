import React from "react"
import Paint from "./paint.js"
import cn from "classnames"
import style from "./border-wrapper.mod.scss"

const BorderWrapper = ({
  children,
  bottomColor,
  topColor,
  isHero,
  topOnly,
}) => {
  return (
    <div
      className={cn(style.borderWrapper, {
        [`${style.borderWrapper}__bottomcolor_${bottomColor}`]: bottomColor,
        [`${style.borderWrapper}__topcolor_${topColor}`]: topColor,
        [`${style.borderWrapper}-hero`]: isHero,
        [`${style.borderWrapper}--top-only`]: topOnly,
      })}
    >
      {children}
      <div className={style.borderWrapper__paint}>
        <Paint />
      </div>
      <div className={style.borderWrapper__br} />
    </div>
  )
}

export default BorderWrapper
