export const getOrders = async (data) => {
  //loop pages and get all orders
  const orders = []
  const allorders = {
    data: {
      orders: []
    }
  }

  let page = 1
  let hasMore = true
  while (hasMore) {
    data.page = page
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "version-code": "1",
        "device-type": "iOS",
        authorization: `Bearer ${data.user.api_token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    }
    try {
      const response = await fetch(
        `${process.env.GATSBY_PORTAL_URL}/order-list`,
        requestOptions
      ).then((res) => {
        return res.json()
      })

      // console.log("POST: api/orders/order-list", response)
      page++
      // console.log("allorders",response)
      if(response.data.orders.length > 0){
        orders.push(...response.data.orders)
        allorders.data.orders = orders
      }else{
        hasMore = false
        return allorders
      }

    } catch (error) {
      hasMore = false
      return error
    }
  }
}

export const getOrder = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/get-order-detail`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/orders/get-order-detail", response)
    return response
  } catch (error) {
    return error
  }
}

export const getOrderStatus = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/check-order-status`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/orders/check-order-status", response)
    return response
  } catch (error) {
    return error
  }
}
