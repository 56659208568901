import React, { useEffect, useState, useContext } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Input from "../form/Input"
import { getUser, setGuestRole } from "../../services/auth"
import { updateProfile, changePassword } from "../../services/profile"
import { AuthContext } from "../../context/AuthContext"
import Button from "../button/button"
import FormHeader from "../form/form-header"
import { getTempUserPassword } from "../../services/cart"
import { toast } from "react-toastify"

const schema = yup
  .object({
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  })
  .required()

const ThankyouSetPasswordForm = ({
  component: Component,
  location,
  handleThankyouSetPasswordForm,
  email,
}) => {
  const [generalMsg, setGeneralMsg] = useState("")
  const [guestTempPass, setGuestTempPass] = useState("")
  const [guestEmail, setGuestEmail] = useState("")

  const { handleSyncUser } = useContext(AuthContext)

  const user = getUser()

  useEffect(() => {
    setGuestEmail(email)
  })

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    defaultValues,
    reset,
    formState: { errors },
  } = methods

  const onSubmit = async (data) => {
    data.user = user
    data.old_password = getTempUserPassword(guestEmail)

    changePassword(data).then((res) => {
      if (res.status) {
        // console.log("onSubmit RES", data)
        toast.success("Your password has been set, we've logged you in!")
        setGuestRole(false)
        handleSyncUser()
        handleThankyouSetPasswordForm()
        reset()
      } else if (res.message) {
        toast.error(res.message)
      }
    })
  }

  return (
    <div>
      {!!generalMsg && <p className="input--error">{generalMsg}</p>}
      <FormHeader
        border
        heading={"Checkout faster next time?"}
        text={
          "Set a password below and simply log in with the email you used in the checkout. We'll save your delivery details along with giving you access to your orders via the account area."
        }
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label={"New Password"}
            name="new_password"
            type="password"
            {...register("new_password")}
            required
            placeholder={"Your New Password"}
            errorMessage={errors.new_password?.message}
            secondary
          />
          <p>
            Password must be between 6-12 characters including one capital, one
            small letter and one number.
          </p>
          <Input
            label={"Confirm New Password"}
            name="confirm_password"
            type="password"
            {...register("confirm_password")}
            required
            placeholder={"Confirm Your New Password"}
            errorMessage={errors.confirm_password?.message}
            secondary
          />
          <Container pt={1}>
            <Button type="submit">Create Password</Button>
          </Container>
        </form>
      </FormProvider>
    </div>
  )
}
export default ThankyouSetPasswordForm
